<template>
	<div class="layout-topbar">
		<button class="p-link layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>
	<!-- <router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span>Terminal Admin</span>
		</router-link> -->
		<button class="p-link layout-topbar-button" @click="onUserToggle">
			<i class="pi pi-user"></i>
		</button>
		<OverlayPanel ref="overlayuser">
			<div class="grid" style="width: 15rem">
				<div class="col-12">
				<Button label="Cancelar Venta" icon="pi pi-ban" class="p-button p-button-max" @click="setEvent('cancel-ticket')"/>
				</div>
				<div class="col-12">
				<Button label="Probar Impresora" icon="pi pi-print" class="p-button p-button-max" @click="setEvent('test-printer')"  />	
				</div>
				<div class="col-12">
				<Button label="Abrir Caja" icon="pi pi-money-bill" class="p-button p-button-max" @click="setEvent('open-cash-drawer')"  />	
				</div>
				<div class="col-12">
				<Button label="Historial de Ventas" icon="pi pi-history" class="p-button p-button-max" @click="setEvent('sale-history')"  />	
				</div>
				<div class="col-12">
				<Button label="Pedidos" icon="pi pi-star" class="p-button p-button-max" @click="setEvent('orders')"  />	
				</div>
				<div class="col-12">
				<Button label="Salir" icon="pi pi-user" class="p-button p-button-max" @click="logout()"  />	
				</div>
			</div>
		</OverlayPanel>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ 
				selector: '@next', 
				enterClass: 'hidden', 
				enterActiveClass: 'scalein', 
				leaveToClass: 'hidden', 
				leaveActiveClass: 'fadeout', 
				hideOnOutsideClick: true
				}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
	</div>
</template>

<script>
import EventBus from './AppEventBus';

export default {
    methods: {
		setEvent(evt) {
			EventBus.emit(evt);
			this.$refs.overlayuser.hide();
		},
		logout() {
			this.$storage.clearStorageSync();
			this.$router.push('/Login')
		},
		onUserToggle(event) {
			this.$refs.overlayuser.toggle(event);
		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.png' : 'images/logo-dark.png';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>
<style>
	.p-button-max {
		width: 100%;
	}
</style>