export default {
    created(el, binding, vnode) {
        el.getElementsByTagName("input")[0].addEventListener('keyup', (e) => {
            e.target.value = binding.arg == "upper" 
                            ? e.target.value.toUpperCase()
                            : binding.arg == "lower" 
                            ? e.target.value.toLowerCase()
                            : e.target.value;           
            //* Buscamos si existe un div adentro
            let div = vnode.children.find(x => x.type == "div");
            if (div) 
                div.children.find(x => x.type.name == "InputText").component.emit("input", e);
        })
    },
}