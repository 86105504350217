<template>
    <router-view></router-view>
</template>

<script>
import EventBus from './AppEventBus';

export default {
    themeChangeListener: null,
    beforeCreate() {
        
        this.themeChangeListener = (event) => {
            const elementId = 'theme-link';
            const linkElement = document.getElementById(elementId);
            const cloneLinkElement = linkElement.cloneNode(true);
            const newThemeUrl = linkElement.getAttribute('href').replace(this.$appState.theme, event.theme);

            cloneLinkElement.setAttribute('id', elementId + '-clone');
            cloneLinkElement.setAttribute('href', newThemeUrl);
            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', elementId);
            });
            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
        
            this.$appState.theme = event.theme;
            this.$appState.darkTheme = event.dark;
            this.$storage.setStorageSync("theme", event.theme);
            this.$storage.setStorageSync("darkTheme", event.dark);
        };

        let theme = this.$storage.getStorageSync("theme") ? this.$storage.getStorageSync("theme") : "bootstrap4-light-blue" ;
        let darkTheme = this.$storage.getStorageSync("darkTheme") ? this.$storage.getStorageSync("darkTheme") : false;
        this.themeChangeListener({ theme: theme, darkTheme: darkTheme});
    },
    mounted() {
        EventBus.on('theme-change', this.themeChangeListener);
    },
    beforeUnmount() {
        EventBus.off('theme-change', this.themeChangeListener);
    }
}
</script>