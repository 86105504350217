<template>
	<div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div id="sidebar" class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <router-view v-slot="{ Component }">
                    <!-- Transicion general del sistema -->
                    <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
                        <component :is="Component" />
                    </FadeInOut>
                </router-view>
            </div>
        </div>

		<AppConfig :layoutMode="layoutMode" @layout-tpv-change="onLayoutTPVChange" @layout-change="onLayoutChange" />
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';

export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu : [
                {
                    label: 'Inicio',
                    items: [{
                        label: 'Panel de Control', icon: 'pi pi-fw pi-home', to: '/'
                    }]
                },
                {
                    label: 'Catalogos',
                    items: [
                        {label: 'Empresa', icon: 'pi pi-fw pi-database', to: '/company'},
                        {label: 'Sucursal', icon: 'pi pi-fw pi-database', to: '/branch'},
                        {label: 'Terminal', icon: 'pi pi-fw pi-database', to: '/terminal'}
                    ]
                },
                {
                    label: 'Herramientas',
                    items: [
                        {label: 'Administrador de Transacciones', icon: 'pi pi-fw pi-credit-card', to: '/transactions'},
                    ]
                },
				{
					label: 'Sesion', icon: 'pi pi-fw pi-clone',
					items: [
                        {label: 'Perfil', icon: 'pi pi-fw pi-user', to: '/profile'},
                        {label: 'Cerrar Sesion', icon: 'pi pi-fw pi-sign-out', logout: true },
					]
				}
            ]
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onLayoutTPVChange(layout) {
            this.$storage.setStorageSync("tpv-layout", layout);
            window.location.reload();
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppConfig': AppConfig
            }
}
</script>

<style lang="scss">
@import './App.scss';

body::-webkit-scrollbar {
	width: 5px;
	}

	/* Track */
body::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px;
	}

	/* Handle */
body::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 50px;
	}

	/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
	background: #555;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2)
	}
    /* width */
#sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#sidebar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
#sidebar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>
