import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import notFound from './pages/NotFound.vue';
import { useStorage } from "vue3-storage";

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/caja',
                name: 'caja',
                component: () => import('./pages/TPV/Caja.vue')
            },
            {
                path: '/productos',
                name: 'productos',
                component: () => import('./pages/TPV/Producto.vue')
            },
            {
                path: '/configuracion',
                name: 'configuracion',
                component: () => import('./pages/TPV/Configuracion.vue')
            },
            {
                path: '/inventario',
                name: 'inventario',
                component: () => import('./pages/TPV/Inventario.vue')
            },
            {
                path: '/clientes',
                name: 'cliente',
                component: () => import('./pages/TPV/Cliente.vue')
            },
            {
                path: '/impresoras',
                name: 'impresora',
                component: () => import('./pages/TPV/Impresora.vue')
            },
            {
                path: '/promociones',
                name: 'promocion',
                component: () => import('./pages/TPV/Promociones.vue')
            },
            {
                path: '/historial',
                name: 'historiasldeventas',
                component: () => import('./pages/TPV/HistorialVentas.vue')
            },
            {
                path: '/pedidos',
                name: 'pedidos',
                component: () => import('./pages/TPV/Pedidos.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
];

routes.push({
    path: '/:pathMatch(.*)*',
    name: '404',
    component: notFound
});

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach(async (to, from) => {
    if (to.name != "login" && to.name != "error") {
        //verificamos autenticacion
        console.log(from)
        const storage = useStorage();
        let user = storage.getStorageSync("user");
        let token = storage.getStorageSync("token");
        if (!user || !token) {
            return { name: 'login' }
        }
    }
})


export default router;
