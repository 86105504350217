<template>
  <Button
    @click="go()"
    type="button"
    class="center p-button-secondary"
    :class="{
      center: true,
      'p-button-info': selected,
      'p-button-primary': selected == false
    }"
  >
    <img alt="logo" :src="image" style="width: 3.5rem" />
    <br />{{text}}
  </Button>
</template>

<script>
export default {
    props: {
        text: null,
        image: null,
        url: null
    },
    methods: {
        go() {
          this.$router.push(this.url)
        }
    },
    computed: {
        selected() {
            return this.$route.path == this.url;
        }
    }
};
</script>

<style>
</style>